<template>
  <tr>
    <td class="column--no-wrap">
      {{ contactMessage.createdAt }}
    </td>
    <td class="v-table-td--icon">
      <v-chip v-if="isNew" label small color="primary" dark>
        NEW
      </v-chip>
    </td>
    <td>{{ contactMessage.firstName }} {{ contactMessage.lastName }}</td>
    <td>{{ contactMessage.email }}</td>
    <td>{{ contactMessage.subject }}</td>
    <td>{{ contactMessage.origin }}</td>
    <td
      v-if="hasRolesOrPermissions('contact_message_view')"
      class="v-table-td--icon"
    >
      <v-btn
        icon
        :to="{
          name: 'contact-messages.show',
          params: { contactMessageId: contactMessage.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapContactMessageGetters } = createNamespacedHelpers(
  "contactMessages"
);

export default {
  name: "contact-message-table-row",
  props: {
    contactMessageId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapContactMessageGetters([FIND_BY_ID]),
    contactMessage() {
      return this[FIND_BY_ID](this.contactMessageId);
    },
    isNew() {
      return isNil(this.contactMessage.viewedAt);
    }
  }
};
</script>
<style scoped>
.column--no-wrap {
  white-space: nowrap;
}
</style>
