<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Contact Message"
      store="contactMessage"
      deletable
      @delete-success="$router.push({ name: 'contact-messages' })"
    />
    <contact-message />
  </v-card>
</template>

<script type="text/babel">
import contactMessageModule from "@/store/modules/contact-message";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("contactMessage");
import { mapDetailViewLifecycleMethods } from "../helpers";
import ContactMessage from "../../components/contact-message/ContactMessage";

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "contactMessage",
  contactMessageModule
);

export default {
  name: "contact-message-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    ContactMessage,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  methods: {},
  ...detailViewLifecycleMethods
};
</script>
