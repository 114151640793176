<template>
  <table-overview
    title="Contact Messages"
    store="contactMessages"
    :headers="[
      { text: 'Date', value: 'created_at', type: 'timestamp' },
      { text: '', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Subject', value: 'subject', sortable: false },
      { text: 'Origin', value: 'origin', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'contact_messages_view'
      }
    ]"
    force-initial-sort
  >
    <contact-message-table-row
      slot="table-row"
      slot-scope="{ item }"
      :contact-message-id="item"
    />
    <contact-message-table-filter slot="filters" />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import contactMessagesModule from "@/store/modules/contact-messages";
import ContactMessageTableRow from "../../components/contact-message/ContactMessageTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";
import ContactMessageTableFilter from "../../components/contact-message/ContactMessageTableFilter";
const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "contactMessages",
  contactMessagesModule
);

export default {
  name: "contact-messages-view",
  components: {
    ContactMessageTableFilter,
    ContactMessageTableRow,
    TableOverview
  },
  data: () => ({}),
  ...overviewLifecycleMethods
};
</script>
