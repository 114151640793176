<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12 md6 class="py-0">
        <v-text-field v-model="firstName" label="First name" readonly />
      </v-flex>
      <v-flex xs12 md6 class="py-0">
        <v-text-field v-model="lastName" label="Last name" readonly />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 md6 class="py-0">
        <v-text-field v-model="email" label="Email" readonly />
      </v-flex>
      <v-flex xs12 md6 class="py-0">
        <v-text-field v-model="origin" label="Origin" readonly />
      </v-flex>
    </v-layout>
    <v-text-field v-model="subject" label="Subject" readonly />
    <v-textarea v-model="message" label="Message" auto-grow readonly />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputed } = createNamespacedHelpers("contactMessage");

export default {
  name: "contact-message",
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed([
      "firstName",
      "lastName",
      "email",
      "message",
      "subject",
      "origin"
    ])
  }
};
</script>
