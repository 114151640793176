var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Contact Messages","store":"contactMessages","headers":[
    { text: 'Date', value: 'created_at', type: 'timestamp' },
    { text: '', sortable: false },
    { text: 'Name', value: 'name' },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Subject', value: 'subject', sortable: false },
    { text: 'Origin', value: 'origin', sortable: false },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'contact_messages_view'
    }
  ],"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('contact-message-table-row',{attrs:{"contact-message-id":item}})}}])},[_c('contact-message-table-filter',{attrs:{"slot":"filters"},slot:"filters"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }