import crudGetters from "@/store/templates/crud/getters";

export default {
  contactMessage: state => state.model,
  id: state => state.model.id,
  firstName: state => state.model.firstName,
  lastName: state => state.model.lastName,
  subject: state => state.model.subject,
  origin: state => state.model.origin,
  email: state => state.model.email,
  message: state => state.model.message,
  ...crudGetters
};
