<template>
  <v-form>
    <v-checkbox
      v-model="isNotSpam"
      label="Is not spam"
      color="primary"
      hide-details
    />
    <v-checkbox v-model="isNew" label="Is new" color="primary" hide-details />
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputedFilters } = createNamespacedHelpers("contactMessages");

export default {
  name: "contact-message-table-filter",
  data: () => ({}),
  computed: {
    ...mapComputedFilters(["isNotSpam", "isNew"])
  }
};
</script>
